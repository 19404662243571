.user {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  min-height: 64px;
  padding-left: 24px;
  padding-top: 24px;
  position: relative;

  &__avatar {
    transition: all 300ms $transition;
  }

  &__block {
    font-size: 12px;
    padding-left: 12px;
    position: relative;
    transition: all 300ms $transition;
  }

  &__name {
    color: #303842;
    font-weight: 600;
    margin: 0;
  }

  &__position {
    color: $colorGreyDark;
  }

  &.is_collapsed {
    .user__avatar {
      bottom: 0;
      position: absolute;
      transform: translateX(4px);
      z-index: 5;
    }

    .user__block {
      font-size: 0;
      left: 65px;
      line-height: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
    }
  }
}
