.main-page-loader {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: linear-gradient(180deg, #8892ff 0%, #6d6be5 18.75%);

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__tremor {
    position: absolute;
    bottom: 0;
  }

  &__tremor:hover {
    animation: 0.1s tremor ease-out infinite;
  }

  &__rocket {
    animation-name: push;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    transform: translate(0, 0);
  }

  &__img {
    @media screen and (max-width: 348px) {
      width: 100%;
    }
  }

  &__text {
    margin-top: 48px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #edf2ff;
  }
}

@keyframes push {
  1% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(0, -250px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes tremor {
  0%,
  25% {
    left: -1px;
    transform: translateX(-0.5px);
    transform: translateX(-0.5px);
  }

  50%,
  100% {
    left: 1px;
    transform: translateX(0.5px);
    transform: translateX(0.5px);
  }
}
