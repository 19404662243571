.companies{
  width: 336px;
}
.dropdown-search{
  position: absolute;
  top: 70px;
}
.drop-container{
  max-height: 144px;
  overflow: auto;
}
