/*
.tooltip_input_tag {
  &__colors {
    padding: 10px 0;
  }

  &__color,
  &__icon {
    border-radius: 50px;
    cursor: pointer;
    position: relative;
  }

  &__color {
    height: 32px;
    width: 32px;

    &.selected {
      &::after {
        background: url("image/icons/white-flag.svg") no-repeat center center;
        background-size: 45%;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &__icons {
  }

  &__icon {
    align-items: center;
    color: $textDark;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    &.selected {
      color: #fff;
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }
}
*/

.default-tooltip {
  //height: 24px;

  &__hide {
    background-color: red !important;
    display: none !important;
  }
}

.default-tooltip-overlay {
  padding-top: 0;
  //display: flex;

  .ant-tooltip-arrow-content {
    // background: #47525f;
  }

  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: #47525f;
    }
  }

  .ant-tooltip-inner {
    padding: 8px 12px;
    min-height: 24px;
    background: #47525f;
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    color: #fff;
  }
}

.default-tooltip-overlay-hide {
  display: none;
}

.default-tooltip-overlay-size {
  max-width: 336px;
}

.default-tooltip-overlay-size-294 {
  max-width: 294px;
}
