::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin: 25px 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(104, 106, 124, 0.4);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 1px rgba(104, 106, 124, 0.9);
}

::-webkit-scrollbar-button {
  display: none;
}