@import "./variables";
//$fontUrl: '../';
@mixin font($font_name, $file_name, $weight, $style) {
  @font-face {
    font-family: $font_name;
    font-display: swap;
    src:
      url("fonts/#{$file_name}.woff") format("woff"),
      url("fonts/#{$file_name}.woff2") format("woff2");
    font-weight: #{$weight};
    font-style: #{$style};
  }
}

@mixin adaptiveText($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $addMobSize: $addSize + $addSize * 0.7;

  @media (max-width: 767px) {
    font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth + px}));
  }

  @media (min-width: 767px) {
    font-size: calc(#{$mobSize + px} + #{$addMobSize} * (100vw / #{$maxWidth + px}));
  }
}

@mixin adaptiveFont() {
  font-size: calc(24px + 16 * ((100vw - 320px) / (1280 - 320)));
}

@mixin adaptive-value($indentType, $pcSize, $mobSize, $maxSize) {
  $addSize: $pcSize - $mobSize;

  @if $maxSize==1 {
    //Только если меньше контейнера
    #{$indentType}: $pcSize + px;

    @media (max-width: #{$maxWidthContainer + px}) {
      #{$indentType}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320}));
    }
  }

  @else if $maxSize==2 {
    //Только если больше контейнера
    #{$indentType}: $pcSize + px;

    @media (min-width: #{$maxWidthContainer + px}) {
      #{$indentType}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
    }
  }

  @else {
    //Всегда
    #{$indentType}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
  }
}

@mixin hide-world() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
