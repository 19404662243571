.multiple-select-option {
  display: inline-flex;
  position: relative;

  &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }

  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
  }

  &[aria-selected="true"] {
    &::before {
      background: $colorSuccessLight url("image/icons/white-flag.svg") no-repeat center center;
      background-size: contain;
      border-color: $colorSuccessLight;
    }
  }

  &::before {
    border: 2px solid $colorGreyLight;
    border-radius: 3px;
    content: "";
    height: 18px;
    margin-right: 12px;
    position: relative;
    top: 2px;
    transition: all $transition 0.3s;
    width: 18px;
  }
}
