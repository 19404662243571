/*
.app-table {
  &__title {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textDark;
  }

  &__block {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    height: 24px;
    width: 24px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: $textMain;
    transition: all 500ms $transition;

    &.active {
      background-color: $colorSecondaryDark;
      color: #fff;
    }
  }

  &__remainder {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 14px;
    color: $colorSecondaryDark;
    transition: all 500ms $transition;
  }
}
*/

.table-default {
  &__pagination {
    display: flex;

    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 24px;
      height: 24px;
    }

    .ant-pagination-item {
      min-width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #6d7895;
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
    }

    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6d7895;
      background-color: transparent;
      border: none;
    }

    .ant-pagination-item-active {
      color: #3843ed;
      border: 1px solid #3843ed;
      border-radius: 8px;
    }

    .anticon svg {
      color: #8d97b0;
    }
  }
}
