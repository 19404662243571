* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::after, *::before {
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input::placeholder {
  font-family: $fontFamily;
  line-height: 24px;
  font-size: 12px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input {
  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
  }
}