$color_1: #597ef7;
$color_2: #9254de;
$color_3: #f759ab;
$color_4: #ffc53d;
$color_5: #ffec3d;
$color_6: #bae637;
$color_7: #ff4d4f;
$color_8: #ff7a45;
$color_9: #ffa940;
$color_10: #ed6161;
$color_11: #363636;
$color_12: #f0f0f0;

// re design

$blue: #4e5af2;
$grey: #cbd5e2;
$grey2: #6d7895;
$grey3: #8d97b0;
