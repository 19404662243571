@keyframes rocket {
  1% {
    rotate: (-5deg);
  }

  50% {
    rotate: (5deg);
  }

  100% {
    rotate: (-5deg);
  }
}

@keyframes planet-1 {
  1% {
    rotate: (0);
  }

  50% {
    transform: scale(1.2) rotate(180deg);
  }

  100% {
    rotate: (0);
  }
}

@keyframes large-star-1 {
  1% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(100px, -100px) rotate(180deg);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes large-star-2 {
  1% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-20px, -100px) rotate(-180deg);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes large-star-3 {
  1% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-20px, -50px) rotate(-90deg);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes large-star-4 {
  1% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-80px, -80px) rotate(-90deg);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes orbita-1 {
  from {
    transform: rotate(0deg) translateX(250px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(250px) rotate(-360deg);
  }
}

@keyframes orbita-2 {
  from {
    transform: rotate(0deg) translateX(252px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(252px) rotate(-360deg);
  }
}

@keyframes orbita-3 {
  from {
    transform: rotate(0deg) translateX(255px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(255px) rotate(-360deg);
  }
}

@keyframes orbita-4 {
  from {
    transform: rotate(0deg) translateX(260px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(260px) rotate(-360deg);
  }
}
