.notification {
  margin: 0 0 10px;
  padding: 0;
  width: 100vw;
  min-height: 48px;
  box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-notification-notice-icon-error {
    //display: none;
  }

  .ant-notification-notice-close {
    top: 14px;
  }

  &__error {
    background: #ff776f;

    .ant-notification-notice-message {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }
  }

  &__success {
    background: #58c65c;

    .ant-notification-notice-message {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }
  }
}
