.file-container{
  .ant-modal {
    height: -webkit-fill-available;
    max-width: 100vw;
  }
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    top: 0;
    overflow: auto;
    background: #DCDEFC;
  }
  .ant-modal-centered::before {
    content: unset;
  }
  .ant-modal-footer {
    display: none;
  }

}
.title{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #20272E;
}

.headerModal {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 24px;
  &_start{
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &_end{
    display: flex;
    gap: 30px;
  }
  svg {
    fill: #6d6be5;
  }

  svg:hover {
    cursor: pointer;
    fill: #3843ed;
  }
}
.carousel {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.carousel-root{
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
}
.view-file-container{
  width: 100vw;
  height: 100vh;
  background: #DCDEFC;
}
.btn-zoom {
  cursor: pointer;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  color: rgba(141, 139, 237, 1);
  background-color: white;
}
.percent{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(71, 82, 95, 1);
  margin: 0 8.5px;
}
